import logo from "../Assets/companyLogo.png";
import "./Header.css";
import Navbar from "./Navbar";

function Header() {
    return (
        <header id="home" className="header">
            <div className="logo-nav">
                <div className="Compant-name-logo">
                    <img className="logo" src={logo} alt="logo" />
                    <div className="name-com">
                        <div className="company-first-name">FX</div>
                        <div className="company-second-name">Code</div>
                    </div>
                </div>
                <Navbar />
            </div>
            <div>
            </div>
        </header>
    );
}

export default Header;